import Vue from 'vue'
import VueFlatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import truncate from 'vue-truncate-collapsed';
import 'scroll-shadow-element';
import 'flatpickr/dist/flatpickr.css';
import 'cropperjs/src/css/cropper.scss';
import 'dropzone/dist/dropzone.css'
import 'vue-select/dist/vue-select.css';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { numeric } from 'vee-validate/dist/rules';
import i18n from './i18n';


// Initialize a new object if it doesn't exist
window.PEERISTICS = window.PEERISTICS || {};



import 'froala-editor/css/froala_editor.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';

import VueFroala from 'vue-froala-wysiwyg/dist/vue-froala.min.js'

Vue.use(VueFroala);


import './icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


Vue.component('font-awesome-icon', FontAwesomeIcon);


Vue.use(VueFlatPickr);


new Vue({
  components: {
    'truncate': truncate
  }
})

Vue.component('content-block-share-vue', () => import('../../vue/content_blocks/share/components/ContentBlockShare.vue'));
Vue.component('container-selection-vue', () => import('../../vue/networks/admin/memberships/components/ContainerSelection.vue'));
Vue.component("flash-vue", () => import('../../vue/shared/flash_messages/components/Flash.vue'));
Vue.component("invite-user-form-vue", () => import('../../vue/networks/admin/memberships/components/InviteUserForm.vue'));
Vue.component('date-picker-vue', () => import('../../vue/shared/schedule_status_change/components/DatePicker.vue'));
Vue.component('calendar-vue', () => import('../../vue/shared/daybook/components/Calendar.vue'));
Vue.component('manage-container-type-features-vue', () => import('../../vue/networks/admin/container_types/components/ManageFeatures.vue'));
Vue.component('manage-container-type-roles-vue', () => import('../../vue/networks/admin/container_types/components/ManageRoles.vue'));
Vue.component('manage-container-type-feature-roles-vue', () => import('../../vue/networks/admin/container_types/components/ManageFeatureRoles.vue'));
Vue.component('objectives-vue', () => import('../../vue/shared/objectives/components/Objectives.vue'));
Vue.component('user-selection-vue', () => import('../../vue/shared/memberships/components/UserSelection.vue'));
Vue.component('scheduled-item-vue', () => import('../../vue/shared/scheduled_items/components/ScheduledItem.vue'));
Vue.component('container-import-vue', () => import('../../vue/networks/admin/containers/components/Import.vue'));
Vue.component('cropper-vue', () => import('../../vue/cropper/components/Cropper.vue'));
Vue.component('filter-bar-main-vue', () => import('../../vue/filter_bar/components/Main.vue'));
Vue.component('upload-vue', () => import('../../vue/file_upload/components/Dropzone.vue'));
Vue.component('manage-survey-vue', () => import('../../vue/surveys/components/ManageSurvey.vue'));
Vue.component('submit-onboarding-survey-vue', () => import('../../vue/surveys/components/SubmitOnboardingSurvey.vue'));
Vue.component('submit-registration-survey-vue', () => import('../../vue/surveys/components/SubmitRegistrationSurvey.vue'));
Vue.component('container-management-vue', () => import('../../vue/containers/admin/components/ContainerManagement.vue'));
Vue.component('container-training-agreement-management-vue', () => import('../../vue/containers/admin/components/ContainerTrainingAgreementManagement.vue'));
Vue.component('container-training-agreement-form-vue', () => import('../../vue/containers/admin/components/ContainerTrainingAgreementForm.vue'));
Vue.component('manage-container-metadata-vue', () => import('../../vue/networks/admin/container_types/components/ManageContainerMetadata.vue'));
Vue.component("logbooks-listing-vue", () => import('../../vue/logbooks/components/Listing.vue'));
Vue.component("logbook-form-vue", () => import('../../vue/logbooks/components/LogbookForm.vue'));
Vue.component("training-program-form-vue", () => import('../../vue/networks/admin/logbooks/components/TrainingProgramForm.vue'));
Vue.component("program-standard-form-vue", () => import('../../vue/networks/admin/logbooks/components/ProgramStandardForm.vue'));
Vue.component("training-program-tabs-vue", () => import('../../vue/networks/admin/logbooks/components/TrainingProgramTabs.vue'));
Vue.component("training-standard-index-vue", () => import('../../vue/networks/admin/logbooks/components/TrainingStandardIndex.vue'));
Vue.component("history-tab-vue", () => import('../../vue/logbooks/components/HistoryTab.vue'));
Vue.component("skills-listing-vue", () => import('../../vue/logbooks/components/SkillsListing.vue'));
Vue.component("skill-sets-listing-vue", () => import('../../vue/logbooks/components/SkillSetsListing.vue'));

Vue.component("training-program-tabs-vue", () => import('../../vue/networks/admin/logbooks/components/TrainingProgramTabs.vue'));
Vue.component("training-standard-index-vue", () => import('../../vue/networks/admin/logbooks/components/TrainingStandardIndex.vue'));
Vue.component("program-standard-index-vue", () => import('../../vue/networks/admin/logbooks/components/ProgramStandardIndex.vue'));

Vue.component("submit-questions-vue",() => import("../../vue/shared/containers/components/SubmitQuestions.vue"));
Vue.component("invite-network-user-vue", () => import('../../vue/shared/memberships/components/InviteNetworkUser.vue'));
Vue.component("loading-animation-vue", () => import('../../vue/shared/loader/components/LoadingAnimation.vue'));
Vue.component("status-form-vue", () => import('../../vue/networks/admin/status_management/components/StatusForm.vue'));
Vue.component("status-listing-vue", () => import('../../vue/networks/admin/status_management/components/StatusListing.vue'));
Vue.component("listing-format-vue", () => import('../../vue/shared/listing/ListingFormat.vue'));
Vue.component("progress-bar-vue", () => import('../../vue/shared/progress_bar/ProgressBar.vue'));
Vue.component("logbooks-widget-vue", () => import('../../vue/widgets/LogbooksWidget.vue'));
Vue.component("breadcrumbs-vue", () => import('../../vue/shared/breadcrumbs/components/Breadcrumbs.vue'));
Vue.component("asset-list-vue", () => import('../../vue/assets/components/AssetList.vue'));
Vue.component("asset-vue", () => import('../../vue/assets/components/Asset.vue'));
Vue.component("logbook-hours-vue", () => import('../../vue/logbooks/components/LogbookHours.vue'));
Vue.component("hours-form-row-vue",  () => import('../../vue/logbooks/components/HoursFormRow.vue'));
Vue.component("hours-history-tab",  () => import('../../vue/logbooks/components/HoursHistoryTab.vue'));
Vue.component("table-top-nav",  () => import('../../vue/shared/table_top_nav/TableTopNav.vue'));
Vue.component("number-input",  () => import('../../vue/shared/inputs/NumberInput.vue'));
Vue.component('v-select', vSelect)
Vue.component('froala-editor-vue', () => import('../../vue/shared/FroalaEditor/FroalaEditor.vue'));
Vue.component('exam-type-index-vue', () => import('../../vue/networks/admin/exams/components/ExamTypeIndex.vue'));
Vue.component('exam-type-form-vue', () => import('../../vue/networks/admin/exams/components/ExamTypeForm.vue'));
Vue.component('exam-index-vue', () => import('../../vue/networks/admin/exams/components/ExamIndex.vue'));
Vue.component('exam-form-vue', () => import('../../vue/networks/admin/exams/components/ExamForm.vue'));
Vue.component("text-input-vue", () => import('../../vue/shared/components/TextInput.vue'));
Vue.component('exam-view-vue', () => import('../../vue/networks/admin/exams/components/ExamView.vue'));
Vue.component('exam-type-view-vue', () => import('../../vue/networks/admin/exams/components/ExamTypeView.vue'));
Vue.component('inventory-exam-index-vue', () => import('../../vue/networks/admin/inventory/components/InventoryExamIndex.vue'));
Vue.component('inventory-exam-report-vue', () => import('../../vue/networks/admin/inventory/components/InventoryExamReport.vue'));
Vue.component('exam-book-index-vue', () => import('../../vue/networks/admin/inventory/components/ExamBookIndex.vue'));
Vue.component('exam-book-form-vue', () => import('../../vue/networks/admin/inventory/components/ExamBookForm.vue'));
Vue.component('sub-location-index-vue', () => import('../../vue/scheduling/components/SubLocationIndex.vue'));
Vue.component('sub-location-form-vue', () => import('../../vue/scheduling/components/SubLocationForm.vue'));
Vue.component('show-sub-location-vue', () => import('../../vue/scheduling/components/ShowSubLocation.vue'));
Vue.component('booking-training-programs-vue', () => import('../../vue/scheduling/booking/components/BookingTrainingPrograms.vue'));
Vue.component('booking-exam-type-vue', () => import('../../vue/scheduling/booking/components/BookingExamType.vue'));
Vue.component('booking-accommodation-vue', () => import('../../vue/scheduling/booking/components/BookingAccommodation.vue'));
Vue.component('booking-sitting-vue', () => import('../../vue/scheduling/booking/components/BookingSitting.vue'));
Vue.component('booking-payment-vue', () => import('../../vue/scheduling/booking/components/BookingPayment.vue'));
Vue.component('booking-user-search-vue', () => import('../../vue/scheduling/booking/components/BookingUserSearch.vue'));
Vue.component('booking-confirmation-vue', () => import('../../vue/scheduling/booking/components/BookingConfirmation.vue'));
Vue.component('booking-form-vue', () => import('../../vue/scheduling/booking/components/BookingForm.vue'));
Vue.component('sitting-form-vue', () => import('../../vue/scheduling/components/SittingForm.vue'));
Vue.component('series-form-vue', () => import('../../vue/scheduling/components/SeriesForm.vue'));
Vue.component('calendar-week-vue', () => import('../../vue/shared/calendar/components/Calendar.vue'));
Vue.component('week-vue', () => import('../../vue/shared/calendar/components/Week.vue'));
Vue.component('session-details-vue', () => import('../../vue/shared/calendar/components/SessionDetails.vue'));
Vue.component('v-select', vSelect)
Vue.component("pagination-vue", () => import('../../vue/shared/pagination/Pagination.vue'));
Vue.component("container-form-vue", () => import('../../vue/networks/admin/containers/components/ContainerForm.vue'));
Vue.component('certificate-form-vue', () => import('../../vue/networks/admin/certificates/components/CertificateForm.vue'));
Vue.component("exam-report-vue", () => import('../../vue/scheduling/components/ExamReport.vue'));
Vue.component("shortcuts-widget-vue", () => import('../../vue/widgets/ShortcutsWidget.vue'));
Vue.component("container-members-list-vue", () => import('../../vue/containers/admin/components/ContainerMembersList.vue'));

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
