class @WysiwygEditor
  @base_options = {
    heightMin: 200,
    pastePlain: true,
    toolbarSticky: false,
    iconsTemplate: 'font_awesome_5',
    language: $('html').attr('lang'),
    key: '6C4A4G4E3C3F3A4B-16D4E3D2E3C3I2E1B10C2E2pBKBOg1a2PQa1ERGRi1B==',
    paragraphFormat:{  N: 'Normal',  H1: 'Heading 1',  H2: 'Heading 2',  H3: 'Heading 3',  H4: 'Heading 4', H5: 'Heading 5', H6: 'Heading 6',  PRE: 'Code'}
  }

  @options = {
    default: {
      toolbarButtons: [
        'peeristicsResource'
        'peeristicsAsset'
        'checkbox'
        '|'
        'fullscreen'
        'bold'
        'italic'
        'underline'
        'strikeThrough'
        'subscript'
        'superscript'
        'fontFamily'
        'fontSize'
        '|'
        'color'
        'emoticons'
        'paragraphStyle'
        '|'
        'paragraphFormat'
        'align'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'indent'
        'quote'
        'insertHR'
        '|'
        'insertTable'
        'undo'
        'redo'
        'clearFormatting'
        'selectAll'
        'html'
        'help'
      ]
      toolbarButtonsMD: [
        'peeristicsResource'
        'peeristicsAsset'
        'checkbox'
        'fullscreen'
        'bold'
        'italic'
        'underline'
        'fontFamily'
        'fontSize'
        'color'
        'paragraphStyle'
        'paragraphFormat'
        'align'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'indent'
        'quote'
        'insertHR'
        'insertTable'
        'undo'
        'redo'
        'clearFormatting'
        'html'
        'help'
      ]

      toolbarButtonsXS: [
        'peeristicsResource'
        'peeristicsAsset'
        'bold'
        'italic'
        'underline'
        'fontFamily'
        'fontSize'
        'color'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'clearFormatting'
        'html'
        'help'
      ]
    }
    no_attach: {
      imagePaste: false
      toolbarButtons: [
        'checkbox'
        '|'
        'fullscreen'
        'bold'
        'italic'
        'underline'
        'strikeThrough'
        'subscript'
        'superscript'
        'fontFamily'
        'fontSize'
        '|'
        'color'
        'emoticons'
        'paragraphStyle'
        '|'
        'paragraphFormat'
        'align'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'indent'
        'quote'
        'insertHR'
        'insertTable'
        '|'
        'undo'
        'redo'
        'clearFormatting'
        'selectAll'
        'html'
        'help'
      ]
      toolbarButtonsMD: [
        'checkbox'
        'fullscreen'
        'bold'
        'italic'
        'underline'
        'fontFamily'
        'fontSize'
        'color'
        'paragraphStyle'
        'paragraphFormat'
        'align'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'indent'
        'quote'
        'insertHR'
        'insertTable'
        'undo'
        'redo'
        'clearFormatting'
        'html'
        'help'
      ]
      toolbarButtonsSM: [
        'bold'
        'italic'
        'underline'
        'fontFamily'
        'fontSize'
        'color'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'clearFormatting'
        'html'
        'help'
      ]
    }
    comments: {
      imagePaste: false
      toolbarButtons: [
        'checkbox'
        'bold'
        'italic'
        'underline'
        '|'
        'paragraphFormat'
        'formatOL'
        'formatUL'
        'insertLink'
        '|'
        'undo'
        'redo'
        'clearFormatting'
        'selectAll'
        'html'
         'help'
      ]
      toolbarButtonsMD: [
        'checkbox'
        'bold'
        'italic'
        'underline'
        'paragraphFormat'
        'formatOL'
        'formatUL'
        'insertLink'
        'redo'
        'clearFormatting'
        'html'
        'help'
      ]
    }
    default_with_templates: {
      toolbarButtons: [
        'peeristicsResource'
        'peeristicsAsset'
        'checkbox'
        'peeristicsTemplateVariable'
        '|'
        'fullscreen'
        'bold'
        'italic'
        'underline'
        'strikeThrough'
        'subscript'
        'superscript'
        'fontFamily'
        'fontSize'
        '|'
        'color'
        'emoticons'
        'paragraphStyle'
        '|'
        'paragraphFormat'
        'align'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'indent'
        'quote'
        'insertHR'
        '|'
        'insertTable'
        'undo'
        'redo'
        'clearFormatting'
        'selectAll'
        'html'
        'help'
      ]
      toolbarButtonsMD: [
        'peeristicsResource'
        'peeristicsAsset'
        'checkbox'
        'fullscreen'
        'bold'
        'italic'
        'underline'
        'fontFamily'
        'fontSize'
        'color'
        'paragraphStyle'
        'paragraphFormat'
        'align'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'indent'
        'quote'
        'insertHR'
        'insertTable'
        'undo'
        'redo'
        'clearFormatting'
        'html'
        'help'
      ]
      toolbarButtonsXS: [
        'peeristicsResource'
        'peeristicsAsset'
        'bold'
        'italic'
        'underline'
        'fontFamily'
        'fontSize'
        'color'
        'formatOL'
        'formatUL'
        'insertLink'
        'outdent'
        'clearFormatting'
        'html'
        'help'
      ]
    }
  }

  constructor: ->
    $.FroalaEditor.DefineIcon('color', {NAME: 'eye-dropper'}) # Use a better icon for the color change menu
    WysiwygEditor.initialize()

  @initialize: (base) ->
    # If no base element is passed, use the document body
    base ||= $('body')

    # Initialize each editor within the base element
    $.each base.find('.live-editor'), (index, editor) ->
      # Get the editor as a jQuery object
      editor = $(editor)

      # Determine the configuration to use
      config = editor.data('editor-config') || 'default'
      options = $.extend({}, WysiwygEditor.base_options, WysiwygEditor.options[config])

      # Set max character limit, if applicable
      options['charCounterMax'] = editor.data('charlimit') || -1

      # Initialize editor with options and bind events
      editor.froalaEditor(options)
      .on 'froalaEditor.image.beforeUpload', (e, editor, files) ->
        if files.length
          reader = new FileReader

          reader.onload = (e) ->
            result = e.target.result
            editor.image.insert result, null, null, editor.image.get()
            return

          reader.readAsDataURL files[0]
        false

      .on 'froalaEditor.image.beforePasteUpload', (e, editor, img) ->
        e.preventDefault()
        $(img).remove()
        editor.commands.exec("peeristicsResource", ['imagePaste'])
        false
        
      .on 'froalaEditor.paste.afterCleanup', (e, editor, clipboard_html) ->        
        e.preventDefault()
        # Empty div needed since .html() only returns HTML string of first element,
        # while we want to return all of the (modified) clipboard_html
        html = $('<div>').append($.parseHTML(clipboard_html))
        
        # If there are images, remove them
        if html.find('img').length > 0
          html.find("img").remove()
          # If image pasting is allowed, show the "Add resources" modal
          if editor.opts.imagePaste then editor.commands.exec("peeristicsResource", ['imagePaste'])
        # Return clipboard_html with images removed
        return html.html()
      .on 'resource.inserted', (e, editor, id) ->
        form = editor.closest('form')
        if id then ResourceAttachable.attach(id, form) else console.log 'No id passed for resource'
      .on 'asset.inserted', (e, editor, id) ->
        form = editor.closest('form')
        if id then AssetAttachable.attach(id, form) else console.log 'No id passed for asset'

    $('body .description_label').each -> 
      
      pathName = window.location.pathname
      editorText = "Editor Shortcut"

      if (pathName.includes('fr'))
        editorText = "Raccourcis d'Éditeur"
      
      helpButton = $('<button aria-label="' + editorText + '"class="accessible-information btn btn-primary btn-sm">' + editorText + '</button>')

      helpButton.on('click', (e) ->
        e.preventDefault()
        $('#modalAccessibleInformation').modal('show')
      )

      $(this).append(helpButton)
window.WysiwygEditor = @WysiwygEditor;
